<template>
   <div>
    <b-modal
      id="financingEditModal" 
      header-class="pb-2"
      size="xl"
      v-model="showModal">

      <template slot="modal-title">
        <div class="row mt-2">
            <div class="col text-left ml-2" style="min-width: 7rem">
              <h4 id="modalLabel" class="text-primary">
                Финансирование
                <small class="text-500">основные данные</small>
              </h4>
            </div>              
          </div>
      </template>

      <b-row class="mt-2 px-1">
        <b-col class="col-6">
          <b-form-group
            label="Название источника финансирования?"
            labele-for="directCostTitle" 
            :invalid-feedback="invalidFeedback"
            :state="titleState">
            <b-form-input 
              id="direcCostTitle"
              v-model="sectionData.title"
              :state="titleState">
            </b-form-input>
          </b-form-group>
          <b-row>
            <b-col sm="3 pr-0">
              <strong class="fs--2">
                Cумма займа
              </strong>
              <b-input-group
                size="sm" append="₽"> 
                <b-form-input 
                  v-model="sectionData.debtValue" 
                  placeholder="-"
                  class="form-control text-right pl-0 pr-1"
                  type="number" 
                  :disabled="!titleState"
                  @update="onChangeValue" 
                  @change="calculatePayments" />
              </b-input-group>  
            </b-col>
            <b-col sm="2 pr-0">
              <strong class="fs--2">
                Cтавка
              </strong>
              <b-input-group
                size="sm" append="%"> 
                <b-form-input 
                  v-model="sectionData.interestRate" 
                  placeholder="-"
                  class="form-control text-right pl-0 pr-1"
                  type="number" 
                  :disabled="!titleState"
                  @update="onChangeValue" 
                  @change="calculatePayments" />
              </b-input-group>   
            </b-col>
            <b-col sm="4">
              <strong class="fs--2">
                Дата получения
              </strong>
              <custom-dropdown
                v-model="dates"
                :selected="sectionData.startDate"
                :disabled="!titleState"
                :defaultItem="sectionData.startDate ? sectionData.startDate.periodIndex : null"
                placeholder="---" 
                size="sm" >
              </custom-dropdown>
            </b-col>
            <b-col sm="3 pl-0">
              <strong class="fs--2">
                Срок займа
              </strong>
              <b-input-group
                size="sm" append="мес."> 
                <b-form-input 
                  v-model="sectionData.paymentPeriods" 
                  placeholder="-"
                  class="form-control text-right pl-0 pr-1"
                  type="number" 
                  :disabled="!titleState"
                  @update="onChangeValue" 
                  @change="calculatePayments" />
              </b-input-group>   
            </b-col>
          </b-row>
        </b-col>
        <b-col class="col-6 mt-2 mb-3">
          <b-col sm="12" class="p-0">
            <b-alert
              class="mt-0 p-1 fs--2 mb-0 px-2"
              :show="true"
              variant="light">
              Добавление кредитов в ваш прогноз-будь то для общего финансирования или для 
              оплаты конкретных покупок, таких как корпоративный автомобиль — это легко. Просто заполните 
              нижеприведенные данные, и мы рассчитаем график амортизации, определим ваши платежи и включим 
              правильные выплаты основного долга и процентов в ваши финансовые прогнозы. <br><br>
              Убедитесь, что вы не добавляете свои платежи по кредиту в качестве отдельного расхода. 
              Если вы уже сделали это, идите и удалите эти расходы. Все, что вам нужно сделать с кредитами, 
              - это рассказать нам о них здесь, и мы позаботимся о деталях окупаемости.
            </b-alert>
          </b-col>
        </b-col>
      </b-row>
      <b-row class="mt-0">  
        <b-col sm="12">
          <div v-if="sectionData.annuityPayment">
            <h3 class="font-proxima font-weight-bold"
              style="position: absolute; top: -0.5rem; right: 25rem; z-index: 10">
              {{ sectionData.annuityPayment | numeralFormat('(0.0a)') }}
              <small class="font-proxima font-weight-bold">₽ </small>
            </h3>
            <h6 class="font-proxima font-weight-bold"
              style="position: absolute; top: 1.5rem; right: 25rem; z-index: 10">
              ежемесячный платеж
            </h6>
          </div>
          <wizard-section 
            :data="sectionData ? sectionData.values : [0]" 
            unitsTitle="рублей"
            titleHeight="2rem"
            :defaultMax="100000"
            :readonly="true"
            :decimals="0">
          </wizard-section>
        </b-col>
      </b-row>


      <template v-slot:modal-footer>

        <div class="w-100">
          <div class="btn btn-outline-dark delete-btn ml-2 px-2 py-0 float-left"
            @click="deleteItem">
            <i class="far fa-trash-alt mr-2 py-0"></i>
            <span class="font-proxima font-weight-light">
              Удалить
            </span>
          </div>
          
          <b-button 
            v-if="loadData"
            class="float-right"
            variant="outline-success"
            size="sm"
            disabled>
            <b-spinner small type="grow"></b-spinner>
            Сохраняем...
          </b-button>
          <b-button
            v-if="!loadData"
            variant="outline-success"
            size="sm"
            class="float-right"
            :disabled="!titleState"
            @click="saveData">
            Готово!
          </b-button>
        </div>

      </template>
      
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'

import CustomDropdown from '../widgets/CustomDropdown'
import WizardSection from '../widgets/Modals/IncomeCreate/WizardSection'

export default {
  data: () => ({
    showModal: false,
    loadData: false,
  }),

  components: {
    CustomDropdown,
    WizardSection,
  },

  computed: {
    bplanStartYear() {
      return this.$store.getters.getActiveBPlan.startYear;
    },

    sectionData() {
      var data = this.$store.getters.getFinancingToEdit
      if (data) {
        return data
      } else {
        return {title: '', titleId: null}
      }
      
    },
    
    dates: {
      get: function() {
        var dateValues = []
        var startDay = moment(`${this.bplanStartYear}-01-01`)
        
        for (var i=0; i < 36; i++) {
          const dateToSelect = moment(startDay).add(i, 'months')
          dateValues.push({
            value: dateToSelect.format(), 
            title: dateToSelect.format('MMMM YYYY'), 
            periodIndex: i
          })
        }

        return dateValues
      },
      
      set: function(value) {
        this.sectionData.startDate = value
        this.calculatePayments()
      } 
    },

    titleState() {
      if (this.sectionData.title.length > 3) {
        return  true
      } else {
        return false
      }
    },

    invalidFeedback() {
      if (!this.sectionData.title) {
        return 'Пожалуйства, введине название...'
      } else if (this.sectionData.title.length < 4) {
        return 'Название должно быть не менее 4-х символов'
      } else {
        return ''
      }
    },
  },

  methods: {
    onChangeValue() {
      this.sectionData.assetValue < 0 ? this.sectionData.assetValue *= -1 : this.sectionData.assetValue
      this.calculatePayments()
    },

    calculatePayments() {
      if (
        this.sectionData.debtValue == 0   || 
        !this.sectionData.startDate       ||
        !this.sectionData.interestRate    ||  
        !this.sectionData.paymentPeriods) 
      {
        return
      }

      const year = moment(this.sectionData.startDate.value).year() - this.bplanStartYear;
      const month = moment(this.sectionData.startDate.value).month();
      var debtValue = parseFloat(this.sectionData.debtValue);
      var paymentPeriods = parseFloat(this.sectionData.paymentPeriods);
      const iterestRate = parseFloat(this.sectionData.interestRate).toFixed(2);
      const emptyData = {
        0: [null, null, null, null, null, null, null, null, null, null, null, null],
        1: [null, null, null, null, null, null, null, null, null, null, null, null],
        2: [null, null, null, null, null, null, null, null, null, null, null, null],
      };
      // Используется для опделения периода поступления денег по кредиту
      const debtIncomeEmptyData = {
        0: [null, null, null, null, null, null, null, null, null, null, null, null],
        1: [null, null, null, null, null, null, null, null, null, null, null, null],
        2: [null, null, null, null, null, null, null, null, null, null, null, null],
      };
      const paymentsEmptyData = {
        0: [null, null, null, null, null, null, null, null, null, null, null, null],
        1: [null, null, null, null, null, null, null, null, null, null, null, null],
        2: [null, null, null, null, null, null, null, null, null, null, null, null],
      };
      const interestPaymentsEmptyData = {
        0: [null, null, null, null, null, null, null, null, null, null, null, null],
        1: [null, null, null, null, null, null, null, null, null, null, null, null],
        2: [null, null, null, null, null, null, null, null, null, null, null, null],
      };
      const principalPaymentsEmptyData = {
        0: [null, null, null, null, null, null, null, null, null, null, null, null],
        1: [null, null, null, null, null, null, null, null, null, null, null, null],
        2: [null, null, null, null, null, null, null, null, null, null, null, null],
      };

      // Запрещам самостоятельное редактирование (если было позволино)
      // Обнуляем данные, т.к они пересчитываются при каждом изменении
      this.sectionData.values = emptyData;
      this.sectionData.payments = paymentsEmptyData;
      this.sectionData.interestPayments = interestPaymentsEmptyData;
      this.sectionData.principalPayments = principalPaymentsEmptyData;
      this.sectionData.debtIncome = debtIncomeEmptyData;

      const montlyInterest = parseFloat((iterestRate / 12 / 100))
      // Расчитываем коэф.аннуитете, необходим для расчета ануитетного платежа
      const annuityRatio = 
        (montlyInterest * Math.pow((1 + montlyInterest), paymentPeriods)) /
        (Math.pow((1 + montlyInterest), paymentPeriods) - 1)
      // Расчитываем ежемесячный платеж
      const annuityPayment = (annuityRatio * debtValue)

      // Записываем ануитетный платеж в переменную для сохранения
      this.sectionData.annuityPayment = annuityPayment

      // Расчитываем динамику задолженности
      for (var i = month; i < 12; i++) {
        if (paymentPeriods > 0 && debtValue >= 0) {
          
          // Устанавливаем выплаты на следующий месяц после получения финансирования
          if (i > month) {
            this.$set(this.sectionData.payments[year], i, parseInt(annuityPayment));
            this.$set(this.sectionData.interestPayments[year], i, parseInt(debtValue * montlyInterest));
            this.$set(this.sectionData.principalPayments[year], i, parseInt(annuityPayment - (debtValue * montlyInterest)));
            debtValue = debtValue - (annuityPayment - (debtValue * montlyInterest));
          }
          // Добавляем запись о дате получения денег по кредиту
          if (i == month) {
            this.$set(this.sectionData.debtIncome[year], i, parseInt(debtValue));
          }
          this.$set(this.sectionData.values[year], i, parseInt(debtValue));
          // уменьшаем задолженность на величину платежа
         
          
        }
        paymentPeriods--
      }

      for (var y = year + 1; y < 3; y++) {
        for (var k = 0; k < 12; k++) {
          if (paymentPeriods > 0 && debtValue >= 0) {
            this.$set(this.sectionData.payments[y], k, parseInt(annuityPayment));
            this.$set(this.sectionData.interestPayments[y], k, parseInt(debtValue * montlyInterest));
            this.$set(this.sectionData.principalPayments[y], k, parseInt(annuityPayment - (debtValue * montlyInterest)));
            // Добавляем запись о дате получения денег по кредиту
            if (i == month) {
              this.$set(this.sectionData.debtIncome[y], k, parseInt(debtValue));
            }
            // уменьшаем задолженность на величину платежа
            debtValue = debtValue - (annuityPayment - (debtValue * montlyInterest))
            this.$set(this.sectionData.values[y], k, parseInt(debtValue));
          }
          paymentPeriods--
        }
      } 
    
    },

    async saveData() {
      this.loadData = true

      if (
        !this.sectionData.title           || 
        !this.sectionData.debtValue       || 
        !this.sectionData.interestRate    || 
        !this.sectionData.startDate       || 
        !this.sectionData.paymentPeriods
      ) {
        this.loadData = false
        console.log('terminate saving!');
        return
      }

      var payload = this.$store.getters.getFinancingToEdit

      payload.paymentPeriods = parseFloat(payload.paymentPeriods)

      try {
        if (payload.id) {
          await this.$store.dispatch('updateFinancingDocument', payload)
        } else {
          await this.$store.dispatch('createFinancingDocument', payload)
        }
      } catch (error) {
        window.console.log('Unable to create or update financing document', error)
      }
      
      this.loadData = false
      this.closeModal()
    },

    closeModal() {
      this.showModal = false
    },

    deleteItem() {
      const payload = this.$store.getters.getFinancingToEdit

      this.$bvModal.msgBoxConfirm('Вы действительно хотите удалить данные?', {
        title: 'Подтвердите удаление',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Да, удалить',
        cancelTitle: 'Нет',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
      .then(async () => {
        await this.$store.dispatch('deleteFinancingDocument', payload)
        this.closeModal()
      })
      .catch(err => {
          // An error occurred
          window.console.log('unable to delete financing document: ', err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.income-title {
  line-height: 90%; 
  margin-top: 2px; 
  margin-bottom: 0.5rem;
}

.income-option {
  padding: 0 5px;
  border: 1px solid lightgray;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: rgba(14, 187, 245, 0.10);
  }
  &.selected {
    background: rgba(14, 187, 245, 0.10);
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.delete-btn {
  color: rgba(145, 145, 145, 0.2);
  border: none;
  span {
    display: none;
  }
  &:hover {
    color: #e63757;
    border-color: rgb(145, 145, 145) !important;
    span {
      display: inline;
    }
  }
}

</style>