<template>
  <div class="w-100 pl-3">
    
    <!-- Баннер нулевых данных  -->
    <div v-if="financingList.length == 0"
      class="card mt-3 mb-3">
      <div class="bg-holder d-none d-lg-block bg-card" 
      style="background-image:url(/img/illustrations/corner-4.png);background-position: center; background-size: cover">
      </div>
      <!--/.bg-holder-->
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12">
            <h3 class="mb-0">Внешнее финансирование</h3>
            <div v-if="false" 
              class="btn btn-outline-light btn-sm fs--0 font-weight-light video-btn" >
              <i class="fas fa-play-circle mr-1"></i>
              Ознакомиться
            </div>
            <hr>
            <h5>Планируете ли вы привлекать кредиты, инвестиции или другое финансирование?</h5> 
            
            <p class="mt-2 mb-0 text-800">
              Добавить источник финансирование в прогноз очень просто. Внесите данные по каждому источнику финансирования, 
              а мы автоматически рассчитаем платежи и соответствующим образом обновим прогноз. 
              Убедитесь, что вы не добавляли платежи по кредиту в постоянные расходы, иначе, в конечном итоге расходы посчитаются дважды.
              Все истояники финансирования вашей компании должны отразиться здесь.
              </p>
            <p v-if="introDescExpanded == false" 
              class="mt-2 mb-0 text-800">
              Не планируете привлекать внешнее финансирование? В таком случае, просто пропустите этот шаг. 
              <a class="ml-2" 
                data-toggle="collapse" 
                href="#readMoreAboutDirectCosts" 
                role="button" 
                aria-expanded="false" 
                aria-controls="readMoreAboutDirectCosts"
                @click="introDescExpanded = true">
                Подробнее ...
              </a>
            </p>
            <div class="collapse" id="readMoreAboutDirectCosts">
                <p class="mt-2 text-800">
                  Не уверены, нужно ли вам внешнее финансирование? 
                  Взгляните на прогноз денежных средств и если там есть отрицательные зоны, то стоит задуматься над перебалансировокой доходов и расходов 
                  или о внешнем финансировании. Помните, что отрицательный остаток денежных средств в любой момент времени, означает, что ваша компания 
                  не сможет оплачивать свои счета и такая ситуация называется - кассовый разрыв.
                </p>
                <p class="mt-2 text-800">
                  Иногда получаемого дохода недостаточно для финансирования динамического роста компании, особенно это актуально для стартапов. 
                  Подумайте, требуется ли вам дополнительное финансирование для сбалансировки бюджета, 
                  финансирования покупок основных активов или для достижения других целей?
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Графики -->
    <div v-if="!financingList.length == 0" 
      class="card mb-3 mt-3 w-100">
      <div class="card-body rounded-soft bg-gradient pt-0 pb-1 dark-blue">
        <div class="card-title">Финансирование</div>
        <div class="card-subtitle">
          {{activeBPlan.startYear + selectedYear}}
        </div>
        <div class="row mt-2">
          <div class="col-sm-8">
            <forecast-chart-light class="w-100"
              :chartData="tableData.activeYearChartData">
            </forecast-chart-light>
          </div>
          <div class="col-sm-4 pl-4">
            <forecast-years-chart-light
              :chartData="tableData.yearsChartData">
            </forecast-years-chart-light>
          </div>
        </div>
      </div>            
    </div>

    <!-- Строка табличных данных -->
    <div v-if="!financingList.length == 0"
      class="row w-100 m-0">
      
      <!-- Таблица с детализацией выручки по году -->
      <div class="col-sm-12 px-0">
        <div class="card mb-2 w-100">
          <div class="card-header d-flex justify-content-end py-2 pr-0">
            <div class="col-sm-auto d-flex flex-center fs--1 mt-1 mt-sm-0">
              <div class="btn-group btn-group-sm" role="group" aria-label="">
                <button 
                  class="btn btn-outline-secondary" 
                  :class="{'active': infoDisplay == 'actualValue'}"
                  @click="infoDisplay = 'actualValue'"
                  type="button">
                  Задолженность
                </button>
                <button 
                  class="btn btn-outline-secondary"
                  :class="{'active': infoDisplay == 'payments'}" 
                  @click="infoDisplay = 'payments'"
                  type="button">
                  График платежей
                </button>
              </div>
              
            </div>
          </div>
  
          <div class="card-body p-0">
            <div class="table-responsive">
              <table-section-render 
                :tableData="tableData"
                :selectedData="infoDisplay"
                @activeYearSelect="(year) => activeYear = year" 
                @openToEdit="openToEdit" />
            </div>
          </div>
          <div class="card-footer p-0">
            <small class="ml-2 fs--2">

            </small>
          </div>
        </div>
      </div>
      <!-- Конец таблицы -->

    </div>
    <hr>
    <div class="d-flex align-content-between row">
      <div class="col-5">
        <button 
          class="btn btn-outline-primary font-weight-normal mr-2"
          @click="createFinancing">
          Добавить финансирование 
        </button>
      </div>
      <div class="col-7 text-right">
        <router-link to="/finance/assets"
          class="btn btn-outline-secondary btn-sm ml-3">
          <i class="fas fa-chevron-left"></i>
          Основные средства
        </router-link>
        <router-link to="/finance/profit-and-loss" 
          class="btn btn-outline-success btn-sm ml-3">
          Готово!
        </router-link>
      </div>
    </div>
    

    <financing-edit-modal />

    
  </div>
</template>

<script>
import ForecastChartLight from '../../components/app/charts/ForecastChartLight'
import ForecastYearsChartLight from '../../components/app/charts/ForecastYearsChartLight'
import FinancingEditModal from '../../components/app/modals/FinancingEditModal'
import TableSectionRender from '../../components/app/Tables/TableSectionRender';

export default {
  data: () => ({
    monthlyDisplay: false,
    infoDisplay: 'actualValue',
    selectedYear: 0,
    introDescExpanded: false,
    activeYear: 0,
  }),

  components: {
    ForecastChartLight,
    ForecastYearsChartLight,
    FinancingEditModal,
    TableSectionRender
  },

  mounted() {
    if ( this.$store.getters.getFinancingList.length == 0 ) {
      this.$store.dispatch('fetchFinancingList')
    }
  },

  computed: {
    activeBPlan() {
      return this.$store.getters.getActiveBPlan;
    },

    financingList() {
      return this.$store.getters.getFinancingList
    },

     tableData() {
      // Загружаем данные по активам из базы данных
      const list = this.financingList || []

      // Переменная для последующего вычисления итогов таблицы
      var tableRows = []
      // Переменная для вычисления информации для графика выбранного года
      var chartData = []
      // Переменная для вычисления информации для графика данных по годам
      var chartYearsData = []

      // Переменная для передачи форматированной таблицы из функции
      var payload = []

      /* 
        По каждой из позиции производим форматирование даных для отображения.
      */
      
      // Пеоеменая для хранения форматированных данных
      var formatedValues, yearData = []
      
      // При выборе отображения "Остаточная" делаем данную калькуляцию
      if (this.infoDisplay == 'payments') {
        for (var i = 0; i < list.length; i++) {
          // Берем каждую позицию и производим ее форматирование
          formatedValues = []
          yearData = []
          for (var y = 0; y < Object.values(list[i].payments).length; y++) {
            const values = Object.values(list[i].payments)[y]
            /* 
              Если расмматриваемый год равен выбранному, то группируем данные
              по квартально и добавляем сумму по году
            */
            if (y == this.activeYear) {
              formatedValues.push(this.$func.arrSum(values.slice(0, 3))) // 1 кв.
              formatedValues.push(this.$func.arrSum(values.slice(3, 6))) // 2 кв.
              formatedValues.push(this.$func.arrSum(values.slice(6, 9))) // 3 кв.
              formatedValues.push(this.$func.arrSum(values.slice(9, 12))) // 4 кв.
              formatedValues.push(this.$func.arrSum(values)) // Итого по году
              chartData.push(values) // Данные для графика выбранного активного года
            } else {
              formatedValues.push(this.$func.arrSum(values)) // Итого по году
            }
            
            // Собираем данные по годам
            yearData.push(this.$func.arrSum(values));
            
          }

         
                  
          const data = {
            type: 'categoryItem',
            title: list[i].title,
            values: formatedValues
          }
  
          tableRows.push(formatedValues)
          payload.push(data)
          chartYearsData.push(yearData)
        }
      }

      // При выборе варианта отображения "Остаточной задолженности"
      if (this.infoDisplay == 'actualValue') {
        for (var k = 0; k < list.length; k++) {
          formatedValues = []
          yearData = []
          // Берем каждую позицию и производим ее форматирование
          for (var yk = 0; yk < Object.values(list[k].values).length; yk++) {
            const values = Object.values(list[k].values)[yk]
            /* 
              Если расмматриваемый год равен выбранному, то группируем данные
              по квартально и добавляем сумму по году
            */
            if (yk == this.activeYear) {
              formatedValues.push(values[2]) // 1 кв.
              formatedValues.push(values[5]) // 2 кв.
              formatedValues.push(values[8]) // 3 кв.
              formatedValues.push(values[11]) // 4 кв.
              formatedValues.push(values[11]) // На конец года
              chartData.push(values) // Данные для графика выбранного активного года
            } else {
              formatedValues.push(values[11]) // На конец года
            }
            // Собираем данные по годам
            yearData.push(values[11]);
          }

          
                  
          const data = {
            type: 'categoryItem',
            title: list[k].title,
            values: formatedValues
          }

          tableRows.push(formatedValues)
          payload.push(data)
          chartYearsData.push(yearData)
        }
      }
      // Расчитываем итоговые значения футера таблицы
      var colsSum = this.$func.colSum(tableRows)
      
      // Расчитываем значения для графика по месяцам
      var activeYearChartData = this.$func.colSum(chartData)
      // Расчитываем значения для графика по месяцам
      var yearsChartData = this.$func.colSum(chartYearsData)

      return { data: payload, total: colsSum, activeYearChartData, yearsChartData }
    },
  },

  methods: {
    async openToEdit(index) {
      await this.$store.commit('setFinancingToEdit', this.$store.getters.getFinancingList[index])
      this.$bvModal.show('financingEditModal')        
    },

    createFinancing() {
      var payload = {
        title: '',
        type: null,
        assetValue: null,
        startDate: null,
        paymentType: null,
        values: {
          0: [null, null, null, null, null, null, null, null, null, null, null, null],
          1: [null, null, null, null, null, null, null, null, null, null, null, null],
          2: [null, null, null, null, null, null, null, null, null, null, null, null],
        },
      }
      this.openToEdit(payload)
      this.$store.commit('setFinancingToEdit', payload)
      this.$bvModal.show('financingEditModal') 
    }
  }
}
</script>

<style lang="scss" scoped>
.video-btn {
  position: absolute;
  top: 0.3rem;
  right: 1rem;
  color: gray !important;
  border-color: gray !important;
}

thead, tfoot {
  font-family: 'Proxima Nova';
  background: #4d596961 !important;
}


.table-sm {
  td {
  height: 25px !important;
  line-height: 20px;
  padding: 2px !important;
  }
}


.title {
  height: 20px;
  &:hover {
    .edit-btn {
      display: inline;
    }
  }
  .edit-btn {
    color: rgba(145, 145, 145, 0.2);
    display: none;
    border: none !important;
    &:hover {
      color: rgb(145, 145, 145);
    }
  }
}

.bg-gradient {
  &.green {
    background-image: linear-gradient(-45deg, #0C271E, #17A674);
  }
  &.red {
    background-image: linear-gradient(-45deg, #b1010c, #db9e86);
  }
  &.neutral {
    background-image: linear-gradient(-45deg, #434a54, #8e959e);
  }
  &.dark-blue {
    background-image: linear-gradient(-45deg, #0f0c29, #1e5e98);
  }
  .card-title {
    position: absolute;
    color: rgba(0,0,0,0.1);
    z-index: 0;
    font-size: 3.5rem;
    top: 0.7rem;
  } 
  .card-subtitle {
    position: absolute;
    color: rgba(0,0,0,0.1);
    z-index: 0;
    font-size: 4.5rem;
    font-weight: 600;
    top: 9rem;
    left: 46%;
    &.s2 {
      left: 3%;
    }
    &.s3 {
      left: 25%;
    }
  } 
  .graph-units {
    position: absolute;
    color: #ffffffb1;
    z-index: 0;
    font-size: 0.7rem;
    top: 0;
  }
}

.highcharts-background {
  background: #b1010c;
}

</style>